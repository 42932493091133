<template>
  <el-dialog
    title="仓位分配"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="供应商" prop="orgId">
          <el-select v-model="dataForm.orgId" :disabled="disabled">
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="公司" prop="companyId">
          <el-select v-model="dataForm.companyId" :disabled="disabled">
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="生效日期" prop="startDate">
          <el-date-picker
            v-model="dataForm.startDate"
            :disabled="disabled"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="终止日期" prop="endDate">
          <el-date-picker
            v-model="dataForm.endDate"
            :disabled="disabled"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="时段" prop="periodId">
          <el-select v-model="dataForm.periodId" :disabled="disabled">
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.withTimeName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="星期" prop="week" class="fullList">
          <el-checkbox-group
            disabled
            v-model="dataForm.week"
            size="medium"
            class="checkBtnColor"
          >
            <el-checkbox-button
              v-for="item in weeksList"
              :key="item.id"
              :label="item.id"
              >{{ item.week }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
        <div class="tablePart">
          <el-button
            type="primary"
            @click="addDataList()"
            class="el-icon-add-location"
            >新增</el-button
          >
        </div>

        <div class="form-table">
          <div class="table-header">
            <div class="table-header-item">冷柜时段</div>
            <div class="table-header-item headWidth150">数量</div>
            <div class="table-header-item headWidth150">操作</div>
          </div>
          <div class="table-body">
            <div
              v-for="comFreezer in dataList"
              :key="comFreezer.orgFreezerId"
              class="table-body-wrap"
            >
              <div class="table-body-item">
                <el-select v-model="comFreezer.orgFreezerId">
                  <el-option
                    v-for="(item, index) in comFreezerList"
                    :key="index"
                    :label="item.freezerPeriodWeeksName"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <div class="table-body-item headWidth150">
                <el-input v-model="comFreezer.space"></el-input>
              </div>
              <div class="table-body-item headWidth150">
                <i
                  @click="handleDeleteItem(comFreezer)"
                  class="el-icon-delete"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataList: [
        {
          id: '',
          comFreezerId: '',
          space: '',
        },
      ],
      dataForm: {
        id: '',
        orgId: '',
        companyId: '',
        periodId: '',
        weeks: '',
        week: [1, 2, 3, 4, 5, 6, 7],
        isUnified: true,
        startDate: '',
        endDate: '',
      },
      orgList: [],
      companyList: [],
      periodList: [],
      comFreezerList: [],
      weeksList: [
        { id: 1, week: '一' },
        { id: 2, week: '二' },
        { id: 3, week: '三' },
        { id: 4, week: '四' },
        { id: 5, week: '五' },
        { id: 6, week: '六' },
        { id: 7, week: '日' },
      ],
      dataRule: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.getOrgList();
    this.getCompanyList();
    this.getPeriodList();
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/orgcompany/comInfo/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = { ...data.orgCompanyVo };
              this.dataForm.week = data.orgCompanyVo.weeks
                ? data.orgCompanyVo.weeks
                    .split(',')
                    .map((item) => parseInt(item))
                : [];
              this.dataList = data.orgCompanyVo.freezerList;
              this.getComFreezerList();
            }
          });
        }
      });
    },
    addDataList() {
      this.dataList.push({
        orgFreezerId: '',
        orgCompanyId: '',
        space: '',
      });
    },
    handleDeleteItem(item) {
      if (this.dataList.length === 1)
        return this.$error('至少需要填写一条记录');
      this.$warning(`是否确认删除${item.assetName}?`).then(() => {
        this.dataList = this.dataList.filter(
          (i) => i.orgFreezerId !== item.orgFreezerId,
        );
      });
    },
    getOrgList() {
      this.$http({
        url: `/cc/org/all`,
        method: 'get',
        params: { type: 0, isUse: 1 },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },

    getCompanyList() {
      this.$http({
        url: `/tc/company/combo`,
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
    getComFreezerList() {
      this.$http({
        url: `/cc/org-freezer/combo`,
        method: 'get',
        params: {
          orgId: this.dataForm.orgId,
          weeks: this.dataForm.weeks,
          periodId: this.dataForm.periodId,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.comFreezerList = data.list;
        }
      });
    },

    getPeriodList() {
      this.$http({
        url: `/cc/global-period/all`,
        method: 'get',
        params: { isUse: 1 },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.periodList = data.data.items;
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let freezerList = this.dataList.map((item) => {
            return { orgFreezerId: item.orgFreezerId, space: item.space };
          });
          this.$http({
            url: `/tc/comFreezer/save`,
            method: 'post',
            data: {
              id: this.dataForm.id,
              freezerList: freezerList,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            } else if (data && data.status === 422) {
              this.$message({
                message: data.errors.space,
                type: 'error',
                duration: 1500,
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.checkBtnColor {
  label.is-checked {
    .el-checkbox-button__inner {
      background-color: #dcdcdc;
    }
  }
}
</style>
